<template>
  <div v-if="pages > 1" class="pagination">
    <LoadMore :disabled="loadedPage === pages" @click.prevent="onLoadMore" />
    <div class="pagination__container">
      <PaginationBtnArrow
        :disabled="currentPage === 1"
        @click="onPageChange(currentPage - 1)"
      />

      <div class="pagination__pages">
        <template v-for="page in pages" :key="page">
          <PaginationBtnNumber
            v-if="isShownNumberButton(page)"
            :class="{ active: page === currentPage }"
            @click="onPageChange(page)"
          >
            <span class="pagination__page-number">
              {{ page }}
            </span>
          </PaginationBtnNumber>
          <span v-else class="pagination__ellipsis">...</span>
        </template>
      </div>

      <PaginationBtnArrow
        :direction-right="true"
        :disabled="currentPage === pages"
        @click="onPageChange(currentPage + 1)"
      />
    </div>
  </div>
</template>

<script setup>
import PaginationBtnArrow from "~/modules/shared/pagination/PaginationBtnArrow.vue";
import PaginationBtnNumber from "~/modules/shared/pagination/PaginationBtnNumber.vue";
import LoadMore from "~/modules/shared/pagination/LoadMore.vue";

const props = defineProps({
  currentPage: { type: Number, required: true },
  pages: { type: Number, required: true },
});

const emits = defineEmits(["onPageChange", "onLoadMore"]);

const loadedPage = ref(0);

const isShownNumberButton = (pageNum) => {
  if (props.pages > 5) {
    return (
      pageNum === 1 ||
      pageNum === props.pages ||
      (pageNum >= props.currentPage - 1 && pageNum <= props.currentPage + 1)
    );
  }

  return true;
};

const onPageChange = (page) => {
  emits("onPageChange", page);

  window.scrollTo({ top: 0, behavior: "smooth" });
};

const onLoadMore = () => {
  loadedPage.value++;

  emits("onLoadMore", loadedPage.value);
};

watch(
  [() => props.currentPage],
  () => {
    loadedPage.value = props.currentPage;
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.pagination {
  @include flex-container(column, flex-start, center);
  gap: 24px;

  &__container,
  &__pages {
    @include flex-container(row, flex-start);
    gap: 16px;

    @include mobile {
      gap: 8px;
    }
  }

  &__pages {
    align-items: flex-end;
  }

  &__page-number {
    @include font(20, 30, 600);
  }

  &__ellipsis {
    width: 13px;
    height: 13px;

    @include flex-container(column, center, center);

    @include font(20, 30, 600);
    text-transform: uppercase;

    cursor: default;

    & + & {
      display: none;
    }
  }
}
</style>
