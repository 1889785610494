<template>
  <button class="pagination-button">
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.pagination-button {
  width: 48px;
  height: 48px;

  @include flex-container(column, center, center);

  border-radius: 50%;

  background-color: white;
  border: 1px solid var(--color-sky-base);

  transition: box-shadow 0.1s ease-in-out;

  @include bigMobile {
    width: 36px;
    height: 36px;
  }

  &:hover {
    border-color: var(--color-primary-base);
    background-color: var(--color-primary-base);
    @extend %shadow;
    color: white;
  }

  &:active {
    border-color: var(--color-primary-dark);
    background-color: var(--color-primary-dark);
    box-shadow: none;
    color: white;
  }

  &:disabled {
    background-color: var(--color-sky-lighter);
    box-shadow: none;
    border-color: var(--color-sky-lighter);

    cursor: auto;

    &:active {
      background-color: var(--color-sky-lighter);
      box-shadow: none;
    }
  }

  &.active {
    border-color: var(--color-primary-dark);
    background-color: var(--color-primary-dark);
    box-shadow: none;
    color: white;

    cursor: auto;

    &:hover {
      border-color: var(--color-primary-dark);
      background-color: var(--color-primary-dark);
      box-shadow: none;
      color: white;
    }
  }
}
</style>
